<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "AGREGAR" : "EDITAR" }} CLIENTE</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Product Image -->
        <template v-if="dataImg">
          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="dataImg" alt="img" class="responsive">
          </div>
          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Update Image</vs-button>
            <vs-button type="flat" color="#999" @click="dataImg = null">Remove Image</vs-button>
          </div>
        </template>
        <!-- Cliente -->
        <vs-input label="Nombre" v-model="dataClientName" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <vs-input label="Segundo Nombre" v-model="dataClientSecondName" class="mt-5 w-full" name="item-name" />
        <vs-input label="Apellidos" v-model="dataClientLastName" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <vs-input label="Apellido Materno" v-model="dataClientMotherLastName" class="mt-5 w-full" name="item-name" v-validate="'required'" />


        <vs-input label="Profesión" v-model="dataClientprofession" class="mt-5 w-full" name="item-name"  />
        <vs-input label="Nacionalidad" v-model="dataClientnationality" class="mt-5 w-full" name="item-name" />
        <vs-input label="Estado Civil" v-model="dataClientmaritalStatus" class="mt-5 w-full" name="item-name" />

        <vs-input label="RUT" v-mask="'##.###.###-X'" v-model="dataClientIdentifier" class="mt-5 w-full" name="item-name" />

        <!-- Contact Info -->
        <vs-input label="Teléfono Fijo" v-model="dataClientContactData.phoneNumber" class="mt-5 w-full" name="item-name" v-mask="'# #### ####'" />
        <vs-input label="Teléfono Celular" v-model="dataClientContactData.cellphoneNumber" class="mt-5 w-full" name="item-name" v-mask="'# #### ####'" />
        <vs-input label="Email" v-model="dataClientContactData.email" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- Addresss Info -->
        <vs-input label="Calle" v-model="dataClientContactData.address.street" class="mt-5 w-full" name="item-name" />
        <vs-input label="Número" v-model="dataClientContactData.address.number" class="mt-5 w-full" name="item-name" />
        <vs-input label="Departamento" v-model="dataClientContactData.address.unitNumber" class="mt-5 w-full" name="item-name"  />
        <vs-input label="Región" v-model="dataClientContactData.address.region" class="mt-5 w-full" name="item-name"  />
        <vs-input label="Comuna" v-model="dataClientContactData.address.city" class="mt-5 w-full" name="item-name"  />
        <vs-input label="Información Adicional" v-model="dataClientContactData.address.aditionalInfo" class="mt-5 w-full" name="item-name"  />

      </div>
    </VuePerfectScrollbar>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData()">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

//import VeeValidate from 'vee-validate'

import { Validator } from 'vee-validate';
import { rutValidator } from 'vue-dni';
import Vue from 'vue';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueTheMask from 'vue-the-mask'

Vue.use(vueNumeralFilterInstaller, { locale: 'es' });

Vue.use(VueTheMask);


Validator.extend('rut', rutValidator);

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id, 
          identifier, 
          name, 
          secondName, 
          lastName, 
          motherLastName,
          profession,
          nationality,
          maritalStatus,
          contactData } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataClientIdentifier = identifier
        this.dataClientName = name
        this.dataClientSecondName = secondName
        this.dataClientLastName = lastName
        this.dataClientMotherLastName = motherLastName
        this.dataClientprofession = profession
        this.dataClientnationality = nationality
        this.dataClientmaritalStatus= maritalStatus
        this.dataClientContactData = contactData
        //his.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {

      dataId: null,
      dataName: "",
      dataImg: null,


      dataType: "",
      dataClientIdentifier: "",
      dataClientName: "",
      dataClientSecondName: "",
      dataClientLastName: "",
      dataClientMotherLastName: "",

      dataClientprofession: "",
      dataClientnationality: "",
      dataClientmaritalStatus: "",

      dataClientContactData: { address: {} },


      dataPrice: 0,

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
          //this.$validator.reset()
          //this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.dataClientName;
    }
  },
  methods: {
    initValues() {
      if (!this.dataId) 
      this.dataId = null
      this.returndataClientIdentifier = ""
      this.dataClientName = ""
      this.dataClientSecondName = ""
      this.dataClientLastName = ""
      this.dataClientContactData = {}

    },
    submitData() {
      //console.log("submitData");
      //this.$validator.validateAll().then(result => {
      //if (this.dataId) {

      var clientRUT = this.dataClientIdentifier;
      var clientRUTlowercase = clientRUT.replace(/[^\w\s]/gi, '');
      clientRUT = clientRUTlowercase.toUpperCase();
      const obj = {
        id: this.dataId,
        identifier: clientRUT,
        name: this.dataClientName,
        secondName: this.dataClientSecondName,
        lastName: this.dataClientLastName,
        motherLastName: this.dataClientMotherLastName,

        profession: this.dataClientprofession,
        nationality: this.dataClientnationality,
        maritalStatus: this.dataClientmaritalStatus,

        contactData: this.dataClientContactData

      }
      //console.log(obj);

      if (this.dataId !== null) {
        //console.log("update run");
        this.$store.dispatch("dataListClient/updateClient", obj)//.catch(err => { /*console.error(err)*/ })
        this.$vs.notify({
        title: 'Datos Actualizados',
        text: 'Es necesario refrescar para ver los datos actualizados.',
        color: 'success',
        fixed: false,
      });
      } else {
        //console.log("new run");
        this.$store.dispatch("dataListClient/newClient", obj)//.catch(err => { /*console.error(err)*/ })
      }
      this.isSidebarActiveLocal = false;

      //this.$emit('closeSidebar')
      //this.initValues()
      //}
      //})
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  },
  components: {
    VuePerfectScrollbar,
  }
}

</script>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}

</style>
