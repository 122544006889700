/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import clientAPI from '@/APIs/boClientAPI'

export default {
  listClients({ commit }, pageObject) {
    return new Promise((resolve, reject) => {
      let url = '';
      let params = {
        params: {
          page: pageObject.page,
          size: pageObject.size
        }
      };

      clientAPI.get(url, params)
        .then((response) => {
          commit('SET_CLIENT', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  // update
  updateClient({ commit }, item) {
    //console.log("update client actions");
    //console.log(JSON.stringify(item.id));
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      clientAPI.put(`${item.id}`, item )
        .then((response) => {
          commit('UPDATE_CLIENT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // New
  newClient({ commit }, item) {
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      clientAPI.post(``, item )
        .then((response) => {
          commit('CREATE_CLIENT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Borrar
  removeItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      clientAPI.delete(`${itemId}`)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
