<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="data-list-list-view" class="data-list-container">
    <client-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="clients">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        </div>
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ clients.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : clients.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=5">
              <span>5</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <template slot="thead">
        <vs-th sort-key="name">Cliente</vs-th>
        <vs-th sort-key="identifier">RUT</vs-th>
        <vs-th sort-key="creationDate">Fecha de Registro</vs-th>
        <!--<vs-th>Acciones</vs-th>-->
        <vs-th>Exportar 
          <feather-icon icon="DownloadIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="exportToExcel()" />
        </vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.identifier">
              <p class="product-name font-medium truncate">{{ tr.name }} {{ tr.lastName }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.identifier | rutFilter  }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.creationDate | formatDate  }} </p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <p>Editar</p>
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
              <!--<feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id)" />-->
            </vs-td>
            <template class="expand-user" slot="expand">
                <div class="vx-row">
                  <!--Datos del Cliente -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Datos del Cliente
                  </vs-divider>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Nombre:</label>
                    <br>
                    {{tr.name}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Segundo Nombre:</label>
                    <br>
                    {{ tr.secondName }} 
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Apellidos:</label>
                    <br>
                    {{ tr.lastName }} 
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Apellidos:</label>
                    <br>
                    {{ tr.motherLastName }} 
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>RUT:</label>
                    <br>
                    {{tr.identifier | rutFilter }}
                  </div>                  
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Profesión:</label>
                    <br>
                    {{tr.profession}}
                  </div>                
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Nacionalidad:</label>
                    <br>
                    {{tr.nationality}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Estado Civil:</label>
                    <br>
                    {{tr.maritalStatus}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Email:</label>
                    <br>
                    {{tr.contactData.email}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Teléfono:</label>
                    <br>
                    {{tr.contactData.phoneNumber}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Celular:</label>
                    <br>
                    {{tr.contactData.cellphoneNumber}}
                  </div>

                  <!--Datos de Direccion -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Dirección
                  </vs-divider>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Calle:</label>
                    <br>
                    {{tr.contactData.address.street}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Número:</label>
                    <br>
                    {{tr.contactData.address.number}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Departamento:</label>
                    <br>
                    {{tr.contactData.address.unitNumber}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Región:</label>
                    <br>
                    {{tr.contactData.address.region}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Comuna:</label>
                    <br>
                    {{tr.contactData.address.city}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Información Adicional:</label>
                    <br>
                    {{tr.contactData.address.aditionalInfo}}
                  </div>


                  <!--Datos Adicionales -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Acciones
                  </vs-divider>
                  <div class="vx-col md:w-1/3 w-full mt-5">
                    <label>Editar Cliente: </label>
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr)" />
                  </div>
                  <div class="vx-col md:w-1/3 w-full mt-5">
                    <label>Panel Cliente: </label>
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="showPanelClient(tr.id)" />
                  </div>
                  <div class="vx-col md:w-1/3 w-full mt-5">
                    <label>Reset Password: </label>
                    <feather-icon icon="email" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="resetClientPassword(tr.id)" />
                  </div><!--
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Eliminar Cliente: </label>
                    <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteProject(tr.id)" />
                  </div>-->
                </div>
            </template>

          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import ClientViewSidebar from '@/views/ui-elements/data-list/ClientViewSidebar.vue'
import moduleClient from "@/store/client/moduleClient.js"
import ClientService from '@/services/ClientService';
import Vue from 'vue';
import { rutFilter } from 'vue-dni';

import vueNumeralFilterInstaller from 'vue-numeral-filter'; 
Vue.use(vueNumeralFilterInstaller, { locale: 'es' });

Vue.filter('rutFilter', rutFilter);

export default {
  components: {
    ClientViewSidebar
  },
  data() {
    return {
      selected: [],
      // products: [],
      itemsPerPage: 5,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      
      fileName: "",
      formats:["xlsx", "csv", "txt"] ,
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["Nombre", "Segundo Nombre", "Apellido", "Segundo Apellido", "RUT", "Profesión", "Nacionalidad", "Estado Civil", "Telefono", "Celular", "Correo", "Calle", "Numero", "No", "Region", "Comuna"],
      headerVal: ["name", "secondName", "lastName", "motherLastName", "identifier", "profession", "nationality", "maritalStatus", "phoneNumber", "cellphoneNumber", "email", "street", "number", "unitNumber", "region", "city"],
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    clients() {
      return this.$store.state.dataListClient.clients
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.clients.length
    }
  },
  methods: {
    exportToExcel() {
      var clientsVar = this.clients
      for (var i = clientsVar.length - 1; i >= 0; i--) {
        clientsVar[i].phoneNumber = clientsVar[i].contactData.phoneNumber
        clientsVar[i].cellphoneNumber = clientsVar[i].contactData.cellphoneNumber
        clientsVar[i].email = clientsVar[i].contactData.email
        clientsVar[i].street = clientsVar[i].contactData.address.street
        clientsVar[i].number = clientsVar[i].contactData.address.number
        clientsVar[i].unitNumber = clientsVar[i].contactData.address.unitNumber
        clientsVar[i].region = clientsVar[i].contactData.address.region
        clientsVar[i].city = clientsVar[i].contactData.address.city
      }
      import('@/export/Export2Excel').then(excel => {
        const list = clientsVar
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        //this.clearFields()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    resetClientPassword(clientId) {
      //console.log(clientId);
      ClientService.clientResetPassword(clientId)
        .then(response => {
          if (response) {
          this.$vs.notify({
            title: 'Password Reset',
            text: 'La contraseña ha sido enviada al usuario',
            color: 'primary',
            fixed: false,
          })
          }
        })
        .catch(error => {
          this.$log.error('Error calling ClientService.clientResetPassword :  - ', error);
        });
    },
    showPanelClient(clientId) {
      localStorage.setItem("clientId", clientId);
      this.$router.push('/clientUser/');
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    deleteData(id) {
      this.$store.dispatch("dataListClient/removeItem", id)//.catch(err => { console.error(err) })
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    getOrderStatusColor(status) {
      if (status == 'PENDING') return "warning"
      if (status == 'ISSUED') return "success"
      return "primary"
    },
    translateStatus(status) {
      if (status == 'PENDING') return "Por finalizar"
      if (status == 'ISSUED') return "Cotizacion emitida"
      return "Indeterminado"
    },
    getPopularityColor(num) {
      if (num > 90) return "success"
      if (num > 70) return "primary"
      if (num >= 50) return "warning"
      if (num < 50) return "danger"
      return "primary"
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    }
  },
  created() {
    if (!moduleClient.isRegistered) {
      this.$store.registerModule('dataListClient',moduleClient)
      moduleClient.isRegistered = true
    }
    let pageObject = {
      page: 0,
      size: 5000
    };
    this.$store.dispatch("dataListClient/listClients", pageObject)
  },
  mounted() {
    this.isMounted = true;
    //console.info("clients");
    //console.info(this.$store.state.dataListClient.clients);
  }
}

</script>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;
        .vs-table--search-input {
          width: 100%;
        }
      }
    }
    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }
    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;
        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      >span {
        display: flex;
        flex-grow: 1;
      }
      .vs-table--search {
        padding-top: 0;
        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;
          &+i {
            left: 1rem;
          }
          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;
      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }
    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;
        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }
    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>