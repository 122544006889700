/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_CLIENT(state, clients) {
    state.clients = clients
  },
  UPDATE_CLIENT(state, client) {
    const clientIndex = state.clients.findIndex((p) => p.id == client.id)
    Object.assign(state.clients[clientIndex], client)
  },
  CREATE_CLIENT(state, client) {
    state.client = client;
  },
  REMOVE_CLENT(state, itemId) {
    const ItemIndex = state.clients.findIndex((p) => p.id == itemId)
    state.clients.splice(ItemIndex, 1)
  },
}
